.navbar-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.navbar-style .dropdown-item.active {
    color: #1476FF !important;
    background-color: #F4F7FF !important;
}

.navbar-style .dropdown-item:active {
    color: unset;
    background-color: unset;
}

.navbar-style .dropdown-item.active .Link:hover {
    background-color: #f3f3f3 !important;
    color: inherit;
}

.navbar-style a {
    color: inherit !important;
}

.navbar-style a:hover {
    background-color: none !important;
}

.dropdown-toggle::after {
    content: "";
    background: url(../../assets/Images/DownArrow.svg);
    height: 18px;
    width: 18px;
    padding: 0 10px;
    margin-top: 4px;
    /* margin-left: 10px; */
    background-repeat: no-repeat;
    background-size: contain;
    border: none !important;
    position: absolute;
    transition: all 0.2s;
}

.show::after {
    margin-top: 10px;
    -webkit-transform: rotate(-180deg) translateY(40%);
    -moz-transform: rotate(-180deg) translateY(40%);
    -ms-transform: rotate(-180deg) translateY(40%);
    -o-transform: rotate(-180deg) translateY(40%);
    transform: rotate(-180deg) translateY(40%);
    transition: all 0.2s;
}

.black > .dropdown-menu {
    border-radius: 23px;
    padding: 8px 15px;
    background-color: black;
    border: 1px solid #302f30;
}
.white > .dropdown-menu {
    border-radius: 23px;
    padding: 8px 15px;
    background-color: white;
}
.Link {
    text-decoration: none;
    margin-left: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
}

.navbar-style .nav-link.active {
    color: #3545EE !important;
}
#collasible-nav-dropdown-4{
    padding: 1px !important;
}

.popover-body-black{
    max-height: 250px;
    overflow: auto;
    background: black;
    color: white;
}

.popover-body-white{
    max-height: 250px;
    overflow: auto;
    background: #f0f0f0;
    color: black;
}
.popover-body::-webkit-scrollbar{
    width: 0px;
}