.nav-item > .active {
    font-weight: 600;
    color: black !important;
}
.nav-item > label {
    font-size: 14px;
}
.signupdiv > div div:nth-child(2) > div{
    padding: 0px;
}
.signupdiv{
    width: 25%;
}
.ForgotButton{
    width: 100%;
    margin-top: 10px;
    border: 1px solid red;
    background: white;
    border-radius: 20px;
    padding: 5px;
    font-weight: 500;
    color: #2a2a2a;
}

.FormDiv{
    position: relative;
}


.selct-cntrr{
    border: 1px solid #E9EAF3;
    box-shadow: 0px 0px 10px #0B162C0D;
    border-radius: 20px;
    
}

.forgotbymobile{
    box-shadow: rgb(233, 234, 243) 0px 0px 10px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}