.infobox {
    background: #EBFFFE;
    border-radius: 14px;
}

.stepcounterforcenter>div>div>div>div {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.accordion-button{
    padding: 5px;
}
.accordion-body{
    padding: 2px;
}
.ant-steps-item-tail{
    padding: 26px 0 0px !important;
}
.ant-steps-vertical{
    height: 36rem !important;
}

.blackmode-on > .ant-steps-vertical > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: white;
}
.blackmode-on > .ant-steps-vertical > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: grey;
}
