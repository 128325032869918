.response-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.response-modal > div {
    width: 360px;
}
.response-modal > div > div {
    border-radius: 30px;
    box-shadow: 0px 0px 1px #E9EAF3;
}

.background_color_change{
    background-color: rgb(224 236 252);
    border-radius: unset !important;
}

.qvaniLogoTitle{
    position: fixed;
    top: 0;
    left: 0;
}