.LoginBg,
.ResetBg,
.ForgotBg,
.SignUpBg {
    background-size: cover;
    width: 100%;
    min-height: 100vh;
}

.LoginBg>div,
.ResetBg>div,
.ForgotBg>div,
.SignUpBg>div,
.user-profile {
    min-height: calc(100vh - 68px);
}

.signupimgbg {
    background: rgba(20, 118, 255, .1);
}

.card {
    border-radius: 15px;
}


.phone-selectr{
    border: 1px solid rgb(233, 234, 243);
    border-radius: 30px;
    padding: 5px;
    box-shadow: rgb(233, 234, 243) 0px 0px 10px;
}

.FormDiv>input ::-ms-reveal {
    display: block !important;
}

.Forgot {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 5px;
    border: none;
    background-color: transparent;
}

.Password {
    border: 1px solid black;
    border-radius: 30px;
    padding: 5px;
    overflow: hidden;
    width: 100%;
}

.Password>input {
    border: none;
    background: transparent;
    width: 86%;
}

.Password>button {
    border: none;
    background: transparent;
}

.Password>input:focus {
    border: none;
    outline: none;
}

.auth-error {
    font-size: 12px;
    font-weight: 600;
}
.nav-item > label {
    font-size: 14px;
}
.Button {
    border: none;
    background-color: #1476FF;
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    padding: 7px 0px;
    border-radius: 45px;
}

.Button:disabled {
    opacity: 0.5;
}

.OtpInputBox {
    display: flex;
    justify-content: center;
}
.OtpInputBox>input {
    border-radius: 10px;
    border: 1px solid black;
    height: 50px !important;
    width: 50px !important;
    -webkit-text-security: square;
}
.OtpInputBox1>input {
    font-size: 20px;
    border-radius: 10px;
    border: 1px solid black;
    height: 40px !important;
    width: 40px !important;
}
.resize:active {
    transform: scale(0.5);
}
.nav-item > .active {
    font-weight: 600;
    color: black !important;
}

/* .logindiv{
    border: none!important;

} */

.logindiv > div div:nth-child(2) > div{
    padding: 0px;
}


.restricted-modal > .modal-dialog{
    display: flex;
    justify-content: center;
    
}
.restricted-modal > .modal-dialog > .modal-content{
    width: 85% !important;
    border-radius: 30px;
}


.css-f6d81q-control{
    border: none;
    box-shadow: none;
}
