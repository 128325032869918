.Cardpositive {
    /* background-color: rgb(154, 204, 154); */
    background-color: #e9f8e9;
    border: 1px solid #e9f8e9;
    border-radius: 8px;
}

.Cardnegative {
    background-color: #f5e2e5;
    border-radius: 8px;
    border: 1px solid #fff9fa;
}

.feedtext {
    font-size: 14px;
    font-weight: 500;
}

.publicuserbackground {
    background: rgba(233 234 243 1);
}

.publicuser-tabs .nav-link {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    color: #6F7182;
}

.publicuser-tabs .nav-item .active {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #1476FF;
}

.publicuser-tabs {
    border-bottom: 1px solid #dee2e6 !important;
}