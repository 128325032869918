.myoffer-tabs .nav-link {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    color: #6F7182;
}

.myoffer-tabs .nav-item .active {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #1476FF;
}

.myoffer-tabs {
    border-bottom: 1px solid #e9e6e6 !important;
}

.expended-row {
    border-bottom: 1px solid darkgray !important;
    background: beige !important;
}