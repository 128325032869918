.logo-footer {
    width: 150px;

}

@media (max-width: 999px) {
    .logo-footer {
        width: 120px;
    }
    
}