.myoffer-tabs{
    border-bottom: 2px solid grey !important;
}
.myoffer-tabs > .nav-item > .nav-link {
    background: transparent;
    border: none;
    color: darkgray;
}

.myoffer-tabs > .nav-item > .active{
    color: #1476FF;
    background: transparent;
    border: none;
    border-bottom: 2px solid #1476FF;
}

.sub_navItems{
    /* width: 230px; */
    text-wrap: nowrap;
}