.text-justify {
    text-align: justify;
}


/* @media screen and (min-width: 720px){
    .qvani-refral-rwrd{
        margin-top: 6rem!important;
    }
    
} */

@media screen and (min-width: 650px){
    .rewrds-para{
        text-align: justify!important;
    }
    
}
