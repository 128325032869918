.verify-two-fa-txn > .OtpInputBox > input {
  width: 40px !important;
  height: 40px !important;
  text-align: center;
  margin-right: 8px !important;
  border-radius: 7px;
  border: 1px solid black;
}

.crypto-send .nav-link {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  outline: none;
  color: #6f7182;
}

.crypto-send .nav-item .active {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #1476ff;
  outline: none;
}

.wallet-page .currencytab {
  display: flex;
  align-items: center;
  border: 2px solid #1476ff;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  outline: none;
  font-size: 13px;
  border-radius: 20px;
  color: black;
  outline: none;
}

.wallet-page .currencytab.nav-link.active {
  display: flex;
  font-size: 13px;
  align-items: center;
  background: #1476ff;
  font-style: normal;
  outline: none;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #1476ff;
  color: #ffffff;
  outline: none;
}

.loadbutton {
  border: none;
  font-size: 16px;
  border-bottom: 1px solid grey;
  font-weight: 500;
  background: transparent;
  outline: none;
}

.navline {
  border-bottom: 1px solid lightslategray !important;
}

.btn-close {
  background: url("../../assets/cross-logo.svg");
  background-repeat: no-repeat;
  color: black;
  opacity: 1;
}

.btn-close:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.table-container {
  width: 100% !important;
  overflow-x: scroll;
  /* border-top-left-radius: 30px; */
  padding: 0px;
  /* border-top-right-radius: 30px; */
  box-shadow: 0px 0px 15px #e9eaf3;
}

.selectCurrency {
  border: none;
}

.loadfiatcard {
  width: 150px;
  margin: 9px 0px;
}

.Table {
  width: 100%;
  background: rgb(167, 213, 252);
  background: -moz-linear-gradient(
    58deg,
    rgba(167, 213, 252, 1) 0%,
    rgba(203, 255, 252, 1) 59%,
    rgba(170, 251, 206, 1) 100%
  );
  background: -webkit-linear-gradient(
    58deg,
    rgba(167, 213, 252, 1) 0%,
    rgba(203, 255, 252, 1) 59%,
    rgba(170, 251, 206, 1) 100%
  );
  background: linear-gradient(
    58deg,
    rgba(167, 213, 252, 1) 0%,
    rgba(203, 255, 252, 1) 59%,
    rgba(170, 251, 206, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7d5fc", endColorstr="#aafbce", GradientType=1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.mywallettable {
  border-radius: 0px !important;
  margin-bottom: 25px;
}

.transaction-table {
  box-shadow: 0px 0px 15px #e9eaf3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.multi-collapse {
  margin: 2px 10px;
  padding: 0px 10px;
}

.accordion-item {
  background: transparent;
  border: none;
  outline: none;
}

.accordion-button {
  border: 1px solid #e9eaf3;
  border-radius: 30px;
  margin-top: 10px;
  font-weight: 500;
  padding: 10px 16px;
  color: black;
}

.dark .accordion-button {
  background: transparent;
  color: #ffffff;
  border: 1px solid white !important;
}

.accordion-button:focus {
  background: #1476ff !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.dark .accordion-button:not(.collapsed) {
  background: black;
  color: #ffffff;
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: black;
  background: white;
  border: 1px solid #e9eaf3;
}

.Button {
  border: none;
  background-color: #1476ff;
  color: white;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  padding: 12px 22px;
  border-radius: 45px;
}

.modal {
  border-radius: 20px;
}

.accordion-button:focus {
  border: 1px solid #e9eaf3 !important;
  box-shadow: none !important;
  background-color: #1476ff !important;
  color: black;
}

.dark .accordion-button::after {
  background-image: url(../../assets/Images/down-arrow-white.svg);
  transform: translateY(5px) !important;
}

.dark .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) translateY(5px) !important;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.dropdown-menu {
  min-width: 6.7rem;
}

.convertTitle {
  color: #0b0e2c;
  font-weight: 500;
  font-size: 24px;
}

.convertTitle span {
  font-weight: 700;
}
.convert-para {
  font-size: 14px;
  font-weight: 400;
}
.input-dev {
  width: 75%;
  margin: 0 auto;
}

.fontSize {
  font-size: 14px;
}

.select-token-option {
  box-shadow: 0px 0px 10px #e9eaf3;
  border-radius: 52px;
  border: 1px solid #e9eaf3;
}
.select-token-option > div > div {
  border: unset;
  box-shadow: unset;
  border-radius: unset;
}

.selectedTokenIconNname > span {
  color: #0b0e2c;
  font-size: 14px;
  font-weight: 500;
}
.selectedTokenIconNname > img {
  width: 23px;
  height: 23px;
}

.selectCurrency > div > div > div.css-1dimb5e-singleValue {
  font-size: 12px;
  color: #1476ff;
  font-weight: 400;
}

.group_button > button {
  background-color: #1476ff;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  outline: unset;
  border: unset;
}
.group_button > button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.min_amoutn {
  font-size: 10px;
  font-weight: 400;
  padding: 2px 5px;
  border-radius: 3px;
  width: fit-content;
  margin: 0;
  border: 1px solid #666666;
}

button.nav-li-link.active {
  color: rgb(20, 118, 255) !important;
}

.add_class > div:first-child > div:first-child>div:first-child> div:first-child{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.oval_loader{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
}

.warning_text{
  font-size: 12px;
  font-weight: 400;
}

.resetOTPBTN{
  border: none;
  outline: unset;
  background-color: transparent;
  color: #1476ff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  .input-dev {
    width: 100%;
  }
  .selectCurrency {
    width: 80px;
  }
}
