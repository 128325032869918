.navbar-toggler {
    border: none;
  }
  
  .navbar-toggler:hover {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
  .dropdown-basic {
    outline: none !important;
  }
  .dropdown-basic ::after{
    display: none !important;
  }