.stripeBand{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* top: -10px; */
}

.imagebgWrapper{
    background-image: url('../../assets/Images/backgroundTrade.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.groupCurrBtn{
    overflow-x: auto;
}

.groupCurrBtn::-webkit-scrollbar {
   display: none;
}
.groupPayBtn{
    overflow-x: auto;
}

.groupPayBtn::-webkit-scrollbar {
   display: none;
}


.groupCurrBtn button:focus-visible {
    background-color: #3545EE;
    color: #FFFFFF;
}
.groupCurrBtn button:focus{
    background-color: #3545EE;
    color: #FFFFFF;
}
.groupPayBtn button:active{
    background-color: #3545EE;
    color: #FFFFFF;
}
.groupPayBtn button:focus{
    background-color: #3545EE;
    color: #FFFFFF;
}

@media screen and (max-width:720px) {
    .imagebgWrapper{
        background-position: top -21px right 44%;
        background-attachment: fixed;

    }

    .trde-crpt{
        margin-top: 2rem;
    }


}


.header-text {
    width: 45%;
}

@media screen and (max-width: 999px){
    .header-text {
        width: 77%;
    }
}

.card-img-top {
    width: 190px;
    height: 140px;
}

.card-img-top-1 {
    width: 170px;
    height: 140px;
}
.card-img-top-2 {
    width: 140px;
    height: 140px;
}



.text-justify {
    text-align: justify;
}