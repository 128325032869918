.profile-tabs .nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #3545ee !important;
  border-radius: 20px !important;
  background: #ffffff !important;
  font-style: normal;
  font-weight: 600;
  color: black;
}

.profile-tabs .nav-item .active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #3545ee !important;
  border-radius: 20px !important;
  background: #3545ee !important;
  color: #ffffff;
}

.otp-input-box {
  display: flex;
  justify-content: center;
}

.otp-input-box > input {
  border-radius: 10px;
  border: 1px solid black;
  height: 50px !important;
  width: 50px !important;
  -webkit-text-security: square;
}

.navProfile {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.tabs-navigator .nav-tabs .nav-link {
  white-space: nowrap;
  color: #6f7182;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.tabs-navigator .nav-tabs .nav-link.active,
.tabs-navigator .nav-tabs .nav-link:focus {
  background-color: transparent !important;
  border: none !important;
  color: #1476ff !important;
}

.text-danger-error {
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.modalEditPic {
  /* width: 475px; */
  height: 225px;
  border-radius: 1.75rem;
  box-shadow: 0px 2px 10px 0px #195dc212;
}

.modalTitleWidth {
  margin: 0 auto;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: justified;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.modalEditPic > .modal-dialog > .modal-content {
  width: 350px;
  border-radius: 1.5rem;
  box-shadow: 0px 2px 10px 0px #195dc212;
}

.modal-header .btn-close {
  margin: 0;
}

.editProfilepicbtn {
  background: #1476ff;
  width: 70%;
  height: 20px;
  padding: 20px 13px;
  border-radius: 18px;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: justified;
}

.editProfilepicbtn:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}

.profile-select {
  border: 1px solid rgb(233, 234, 243);
  box-shadow: rgb(233, 234, 243) 0px 0px 10px;
  border-radius: 30px;
  /* padding: 5px */
}

.selct-drop-profile .css-1fdsijx-ValueContainer {
  padding: 2px 20px !important;
}

.profEmail {
  box-shadow: rgb(233, 234, 243) 0px 0px 10px;
  border-radius: 52px;
}

.faded-text {
  color: #1476ff;
  opacity: 0.5;
  pointer-events: none;
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .flex_grow {
    flex-grow: 1;
  }
}
@media screen and (max-width: 450px) {
  .tabs-navigator .nav-tabs .nav-link {
    font-size: 14px;
  }
}
